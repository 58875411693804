import {mainSplitApi} from "../../../api"

const user = mainSplitApi.injectEndpoints({
    endpoints: build => ({
        getProfileData: build.query({
            query: () => "user/v1/profile",
            transformResponse: (response: any) => {
                return response.data
            },
            providesTags: ["user"],
        }),

        updatePhone: build.mutation({
            query: body => ({
                url: `user/v1/profile`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["user"]),
        }),

        editProfileData: build.mutation({
            query: body => ({
                url: `user/v1/profile`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["user"]),
        }),
    }),
})

export const {useGetProfileDataQuery, useUpdatePhoneMutation, useEditProfileDataMutation} = user
