import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import urls from "../constants/urls"

export const mainSplitApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: urls.BASE_URL,
        prepareHeaders: headers => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const user_info = localStorage.getItem("user_info")
            const user_info_obj = user_info && JSON.parse(window.atob(user_info))
            const token = user_info_obj?.token
            if (token) {
                headers.set("authorization", `${token}`)
            }
            return headers
        },
    }),

    endpoints: () => ({}),

    tagTypes: [
        "State",
        "City",
        "token",
        "registered",
        "metric",
        "details",
        "auth",
        "mapped",
        "admin",
        "user",
        "toast",
        "registered_details",
        "registered_metric",
        "error404",
        "table_filters",
        "broadcast",
        "user",
        "settlements",
        "fiscalyear",
        "settlements_charger",
        "sessions",
    ],
})
