import Typography from "@mui/material/Typography"
import OtpForm from "./Features/OtpForm"
import styles from "./login.module.scss"
import {useLogin} from "./useLogin"
import Box from "@mui/material/Box"
import LoginForm from "./Features/loginForm"
import LoadingButton from "../../components/Button"

const Login = () => {
    const {states, functions, formik} = useLogin()

    return (
        <div className={styles.login_wrapper}>
            <div className={styles.form_wrapper}>
                <Typography variant="h1" sx={{fontSize: 32, paddingBottom: "1rem"}}>
                    Login
                </Typography>
                <Typography variant="h6" sx={{paddingBottom: "2rem"}}>
                    Welcome to Statiq
                </Typography>
                {!states.renderOTPForm && (
                    <form onSubmit={formik.handleSubmit}>
                        <LoginForm
                            id="phone"
                            name="phone"
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            value={formik.values.phone}
                            changehandler={formik.handleChange}
                        />

                        <LoadingButton
                            loading={states.isLoading}
                            variant="contained"
                            type="submit"
                            sx={{
                                width: "100%",
                                marginTop: "1.5rem",
                                marginBottom: "1rem",
                                py: "1.6rem",
                                display: "flex",
                            }}
                        >
                            Login
                        </LoadingButton>
                    </form>
                )}

                {states.renderOTPForm && (
                    <form onSubmit={functions.handleOtpSubmit}>
                        {states.renderOTPForm && (
                            <OtpForm
                                loginLoading={states.loginLoading}
                                handleResendOtp={functions.handleResendOtp}
                                handleOtpChange={(data: any) => functions.setOtpValue(data)}
                                error={states.loginError}
                                resendOTPData={states.data}
                            />
                        )}
                        <LoadingButton
                            loading={states.loginLoading}
                            variant="contained"
                            type="submit"
                            sx={{width: "100%", py: "1.5rem", my: "1rem"}}
                        >
                            Verify
                        </LoadingButton>
                    </form>
                )}

                <Box sx={{display: "flex"}}>
                    <Typography variant="body2" sx={{marginRight: "5px", color: "#70758D"}}>
                        Forgot your Login details?
                    </Typography>
                    <Typography variant="body2" className={styles.form_wrapper__get_help}>
                        Get help
                    </Typography>
                </Box>
            </div>
        </div>
    )
}

export default Login
