import {useState} from "react"
import Box from "@mui/material/Box"
import open_icon from "../../assets/open_arrow.svg"
import back_icon from "../../assets/back_arrow.svg"
import AppBar from "../../pages/UserProfile/Features/AppBar"
import SideMenu from "../../components/SideMenu"

export default function MiniDrawer({children}: any) {
    const [open, setOpen] = useState(false)
    const [hover, setHover] = useState(false)

    return (
        <Box sx={{display: "flex", height: "100vh", background: "#F4F4F7", position: "relative"}}>
            {/* <Header opened={open} /> */}
            <SideMenu open={open} setHover={(data: any) => setHover(data)} isHover={hover} />
            <Box sx={{position: "absolute", left: open ? "224px" : "43px", top: 13}}>
                <img
                    src={open ? back_icon : open_icon}
                    style={{cursor: "pointer"}}
                    alt="statiq"
                    onClick={() => setOpen(!open)}
                />
            </Box>

            <Box component="main" sx={{flexGrow: 1, flexDirection: "column", overflow: "auto"}}>
                <Box
                    component="div"
                    sx={{display: "flex", justifyContent: "flex-end", background: "#fff", padding: "14px 32px"}}
                >
                    <AppBar />
                </Box>
                <Box component="div" sx={{p: "16px 16px", background: "#F4F4F7"}}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
