import {useNavigate, Link} from "react-router-dom"
import menuIcon from "../../../../assets/menu_arrow.svg"
import Avatar4 from "../../../../assets/avatar_4.svg"
import styles from "./AppBar.module.scss"
import AvatarEditIcon from "../../../../assets/avatar_edit_icon.png"
import MenuPopup from "../../../../components/MenuPopup"
import useUserProfile from "../../useUserProfile"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const AppBar = () => {
    const {states, functions} = useUserProfile()
    const navigate = useNavigate()

    return (
        <div style={{width: "fit-content"}}>
            {states.profileLoading ? (
                <>Loading...</>
            ) : (
                <Grid
                    onClick={functions?.handleClick}
                    container
                    sx={{gap: "9px", cursor: "pointer"}}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <Box className={styles.avatar_container}>
                            <img style={{width: 29, height: 29}} src={Avatar4} alt="User Profile" />
                        </Box>
                    </Grid>

                    <Grid item>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "18px",
                            }}
                        >
                            {states?.profileData?.name
                                .split(" ")
                                .map((word: string) => word[0])
                                .join("")
                                .toUpperCase()}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#70758D",
                            }}
                        >
                            {states?.profileData?.company_name}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <img src={menuIcon} alt="menu" />
                    </Grid>
                </Grid>
            )}

            <MenuPopup
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -14,
                    horizontal: 50,
                }}
                anchorEl={states?.anchorEl}
                handleClose={functions?.handleClose}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "163px",
                        background: "#FFFFFF",
                        boxShadow: "-3px -3px 6px rgba(172, 172, 172, 0.15), 3px 3px 6px rgba(172, 172, 172, 0.15)",
                        borderRadius: "5px",
                        paddingTop: "8px",
                    }}
                >
                    <Link to="/user-profile">
                        <Box className={styles.avatar_container}>
                            <Box className={styles.avatar_container__avatar}>
                                <img
                                    className={styles.avatar_container__avatar__icon}
                                    src={Avatar4}
                                    alt="User Profile"
                                />
                                <img
                                    className={styles.avatar_container__avatar__edit_icon}
                                    src={AvatarEditIcon}
                                    alt="User Profile"
                                />
                            </Box>
                        </Box>
                    </Link>

                    <Box
                        sx={{
                            padding: "12px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "18px",
                            }}
                        >
                            {states?.profileData?.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#70758D",
                                wordBreak: "break-all",
                                marginTop: "8px",
                            }}
                        >
                            {states?.profileData?.email}
                        </Typography>

                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#70758D",
                                marginTop: "8px",
                            }}
                        >
                            {states?.profileData?.company_role}
                        </Typography>
                    </Box>

                    <Box
                        onClick={() => {
                            localStorage.clear()
                            navigate("/")
                        }}
                        sx={{
                            padding: "12px",
                            borderTop: "1px solid #E8E8EC",
                            cursor: "pointer",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "18px",
                                color: "#FC441E",
                            }}
                        >
                            Logout
                        </Typography>
                    </Box>
                </Box>
            </MenuPopup>
        </div>
    )
}

export default AppBar
