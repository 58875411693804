import charger_icon from "../assets/menu/charger_management_icon.svg"
import location_icon from "../assets/menu/location_icon.svg"
import tariff_icon from "../assets/menu/tariff_icon.svg"
import token_icon from "../assets/menu/token_icon.svg"
import session_icon from "../assets/menu/sessions_icon.svg"
import logs_icon from "../assets/menu/logs_icon.svg"

export const MenuListHub: any = [
    {
        icon: charger_icon,
        name: "Company Management",
        path: "/company-management",
        child: [
            {
                icon: "",
                name: "Profile",
                path: "/company-management/profile",
            },
            {
                icon: "",
                name: "Registration",
                path: "/company-management/registration",
                submenu_path: "/company-management/registration/details",
            },
            {
                icon: "",
                name: "Mapping",
                path: "/company-management/mapping",
            },
            {
                icon: "",
                name: "Admin",
                path: "/company-management/admin",
            },
            {
                icon: "",
                name: "Settlements",
                path: "/company-management/settlements",
            },
        ],
    },
    {
        icon: location_icon,
        name: "Locations",
        path: "/locations",
    },
    {
        icon: tariff_icon,
        name: "Tariff",
        path: "/tariff",
    },
    {
        icon: token_icon,
        name: "Tokens",
        path: "/tokens",
    },
    {
        icon: session_icon,
        name: "Sessions",
        path: "/sessions",
    },
    {
        icon: logs_icon,
        name: "Logs",
        path: "/logs",
    },
]

export const MenuList: any = [
    {
        icon: charger_icon,
        name: "Company Management",
        path: "/company-management",
        child: [
            {
                icon: "",
                name: "Profile",
                path: "/company-management/profile",
            },
            {
                icon: "",
                name: "Mapping",
                path: "/company-management/mapping",
            },
            {
                icon: "",
                name: "Admin",
                path: "/company-management/admin",
            },
            {
                icon: "",
                name: "Settlements",
                path: "/company-management/settlements",
            },
        ],
    },
    {
        icon: location_icon,
        name: "Locations",
        path: "/locations",
    },
    {
        icon: tariff_icon,
        name: "Tariff",
        path: "/tariff",
    },
    {
        icon: token_icon,
        name: "Tokens",
        path: "/tokens",
    },
    {
        icon: session_icon,
        name: "Sessions",
        path: "/sessions",
    },
]
