import icon500 from "../../assets/500.svg"
import LoadingButton from "../Button"
import style from "./index.module.scss"

const FiveHundredError = () => {
    const handleReload = () => {
        window.location.reload()
    }

    return (
        <div className={style.container}>
            <img src={icon500} />
            <p>
                There is always time for a coffee break <br />
                We will be back by the time you finish your coffee.
            </p>
            <LoadingButton type="submit" variant="contained" onClick={() => handleReload()}>
                Refresh
            </LoadingButton>
        </div>
    )
}
export default FiveHundredError
