import {MiddlewareAPI, isRejectedWithValue} from "@reduxjs/toolkit"
import {setError404Func} from "../components/Table/404/Error404Slice"
import {setAlertFunc} from "../components/Toaster/ToasterSlice"

export const rtkQueryErrorLogger =
    ({dispatch}: MiddlewareAPI) =>
    (next: (arg0: any) => any) =>
    (action: any) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
        if (isRejectedWithValue(action)) {
            console.log("We got a rejected action!", action.payload)
            if (action.payload.status !== 409) {
                // dispatch(
                //     setAlertFunc({
                //         type: "error",
                //         message: action?.payload?.error
                //             ? action.payload.error
                //             : action?.payload?.data?.meta?.message
                //             ? action.payload.data.meta.message
                //             : action.payload.data.detail,
                //     })
                // )
            }
            if (action.payload.status === 401) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: action?.payload?.error
                            ? action.payload.error
                            : action?.payload?.data?.meta?.message
                            ? action.payload.data.meta.message
                            : action.payload.data.detail,
                    })
                )
                setTimeout(() => {
                    window.location.href = "/auth"
                }, 100)
            }
            if (action.payload.status === 404) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: action?.payload?.error
                            ? action.payload.error
                            : action?.payload?.data?.meta?.message
                            ? action.payload.data.meta.message
                            : action.payload.data.detail,
                    })
                )
            }
            if (action.payload.status === 500) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: action?.payload?.error
                            ? action.payload.error
                            : action?.payload?.data?.meta?.message
                            ? action.payload.data.meta.message
                            : action.payload.data.detail,
                    })
                )
            }
        }
        if (action?.payload?.length > 0) {
            dispatch(
                setError404Func({
                    enable: false,
                })
            )
        }
        return next(action)
    }
