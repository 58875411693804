import {Outlet, Navigate, useLocation} from "react-router-dom"
import Hub from "../layout/Hub"

const RequiredAuth = () => {
    const user_info = localStorage.getItem("user_info")
    const user_info_obj = user_info && JSON.parse(window.atob(user_info))
    const token = user_info_obj?.token

    const location = useLocation()

    return (
        <>
            {token ? (
                <Hub>
                    <Outlet />
                </Hub>
            ) : (
                <Navigate to="/auth" replace state={{from: location}} />
            )}
        </>
    )
}

export default RequiredAuth
