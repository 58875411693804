import List from "@mui/material/List"
import MenuItems from "./menuItems"

interface ListProps {
    [key: string]: any
}

const MenuListItems = ({
    icon,
    name,
    child,
    open,
    path,
    active,
    isHover,
    parentActiveMenu,
    setParentActiveMenu,
}: ListProps) => {
    return (
        <List>
            <MenuItems
                icon={icon}
                name={name}
                child={child}
                open={open}
                isHover={isHover}
                path={path}
                active={active}
                parentActiveMenu={parentActiveMenu}
                setParentActiveMenu={setParentActiveMenu}
            />
        </List>
    )
}
export default MenuListItems
