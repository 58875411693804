import type {} from "@mui/x-data-grid/themeAugmentation"

export const MuiDataGrid = {
    styleOverrides: {
        root: {
            background: "#fff",
            borderRadius: 10,
            fontFamily: "'MarkPro'",
            fontSize: 12,
            p: {
                fontSize: "12px !important",
            },
            ".MuiDataGrid-cell": {
                padding: "8px",
                whiteSpace: "normal !important",
            },
            ".MuiDataGrid-cell:focus": {
                outline: "none",
            },
            ".MuiDataGrid-cell:focus-within": {
                outline: "none",
            },
            ".MuiDataGrid-row:hover": {
                background: "rgba(0 0 0 / 2%)",
            },
            ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: " none",
            },
        },
    },
}
