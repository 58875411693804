import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import {MuiOtpInput} from "mui-one-time-password-input"
import {useEffect, useState} from "react"

type Props = {
    formik?: any
    handleOtpChange?: any
    handleResendOtp?: (event: any) => void
    loginLoading?: boolean
    error: any
    resendOTPData?: any
    renderOTPForm?: any
}

const OtpForm = (props: Props) => {
    const [otp, setOtp] = useState("")
    const [timer, setTimer] = useState<number>(30)
    const [showMsg, setShowMessage] = useState({show: false, msg: "", success: false})

    const handleChange = (newValue: any) => {
        setOtp(newValue)
        props.handleOtpChange(newValue)
    }

    useEffect(() => {
        let timeoutID: any
        if (timer >= 0) {
            timeoutID = setTimeout(() => {
                setTimer(state => state - 1)
            }, 1000)
        }
        return () => {
            clearTimeout(timeoutID)
        }
    }, [timer])

    useEffect(() => {
        const meta = props?.error?.data?.meta
        if (props?.error) {
            setShowMessage({show: true, msg: meta?.message, success: meta?.success})
        }
    }, [props?.error])

    useEffect(() => {
        if (props?.renderOTPForm) {
            setShowMessage({show: false, msg: "", success: false})
        }
    }, [props?.renderOTPForm])

    useEffect(() => {
        const meta = props?.resendOTPData?.meta
        if (meta) {
            setShowMessage({...showMsg, success: meta?.success, msg: meta?.message})
        }
    }, [props?.resendOTPData])

    return (
        <div className={showMsg?.show && !showMsg?.success ? "otp_container" : ""}>
            <Typography variant={"body1"} sx={{fontWeight: "500"}}>
                Enter OTP?
            </Typography>
            <MuiOtpInput
                sx={{borderRadius: "2px", gap: "10px"}}
                autoFocus
                length={6}
                value={otp}
                onChange={handleChange}
            />
            {showMsg?.show && (
                <p className={!showMsg?.success ? "login_error_message" : "login_success_message"}>{showMsg?.msg}</p>
            )}
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography variant="body2" sx={{my: 2, marginRight: "5px", color: "#70758D"}}>
                    Didn&#39;t receive OTP?
                </Typography>
                {timer <= 0 ? (
                    <Link
                        style={{cursor: "pointer", color: "#FC441E", textDecorationColor: "#FC441E"}}
                        onClick={(event: any) => {
                            props.handleResendOtp && props.handleResendOtp(event)
                            setTimer(30)
                            setOtp("")
                        }}
                    >
                        Resend OTP
                    </Link>
                ) : (
                    <Typography variant="body2">00:{timer > 9 ? timer : "0" + timer}</Typography>
                )}
            </Box>
        </div>
    )
}

export default OtpForm
