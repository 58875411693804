import {styled, Theme, CSSObject} from "@mui/material/styles"
import MuiDrawer, {DrawerProps} from "@mui/material/Drawer"
import {MenuList, MenuListHub} from "../../constants/menuItems"
import MenuListItems from "./menuLists"
import full_logo from "../../assets/full_logo.png"
import small_logo from "../../assets/small_logo.png"
import {useLocation} from "react-router-dom"
import {useState} from "react"

const drawerWidth = 246

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    // borderTopRightRadius: 20,
    // borderBottomRightRadius: 20,
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    // borderTopRightRadius: 20,
    // borderBottomRightRadius: 20,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 24,
    paddingRight: 20,

    ...theme.mixins.toolbar,
}))

interface MuiDrawerProps extends DrawerProps {
    isHover?: boolean
}

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== "open" && prop !== "isHover"})<MuiDrawerProps>(
    ({theme, open, isHover}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        position: isHover ? "initial" : "sticky",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": {...closedMixin(theme), width: isHover && "246px !important"},
        }),
    })
)

const SideMenu = ({open, setHover, isHover}: any) => {
    const [parentActiveMenu, setParentActiveMenu] = useState(false)

    const location = useLocation()

    const userRole = JSON.parse(atob(localStorage.getItem("user_info") ?? ""))

    const newMenuList = userRole?.company_role === "hub" ? MenuListHub : MenuList

    return (
        <Drawer
            variant="permanent"
            open={open}
            isHover={isHover}
            onMouseEnter={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
        >
            <DrawerHeader>
                {open || isHover ? (
                    <img style={{height: "25px"}} src={full_logo} alt="statiq" />
                ) : (
                    <img style={{height: "18px"}} src={small_logo} alt="statiq" />
                )}
            </DrawerHeader>
            {newMenuList.map((menu: any) => {
                return (
                    <MenuListItems
                        key={menu.path}
                        {...menu}
                        open={open}
                        isHover={isHover}
                        active={location.pathname}
                        parentActiveMenu={parentActiveMenu}
                        setParentActiveMenu={setParentActiveMenu}
                    />
                )
            })}
        </Drawer>
    )
}

export default SideMenu
