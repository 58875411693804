const urls = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    AUTH_URL: "auth",
    COMPANY_URL: "company",
    LOCATION_URL: "location",
    TOKEN_URL: "token",
    TARIFF_URL: "tariff",
    SESSION_URL: "session",
    LOGS_URL: "logs",
    USER_URL: "user",
    SETTLEMENT_URL: "settlements",
}
export default urls
