import {TextField, Box, FormLabel, FormControl, InputAdornment} from "@mui/material"
import styles from "./Input.module.scss"
import IconComponent from "../IconComponent"

type InputProps = {
    label?: string
    placeholder?: string
    varient?: string
    type?: string
    value?: string | number
    [key: string]: any
    required?: boolean
    style?: any
    labelFontWeight?: any
    disabled?: boolean
    icon?: string
    tooltipText?: any
    iconClickHandler?: any
    autoFocus?: any
}

const style = {margin: "12.5px 0px"}

const Input = (props: InputProps) => {
    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <FormControl sx={props.style ? props.style : style}>
                <FormLabel sx={{fontWeight: props?.labelFontWeight}} required={props.required}>
                    {props.label}
                </FormLabel>
                <TextField
                    autoFocus={props?.autoFocus}
                    InputProps={{
                        autoFocus: props?.autoFocus,
                        className: styles.input,
                        endAdornment: props?.icon && (
                            <InputAdornment position="end">
                                <IconComponent
                                    icon={props?.icon}
                                    alt={props?.tooltipText}
                                    onClick={props?.iconClickHandler}
                                />
                            </InputAdornment>
                        ),
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: styles.input__error_message,
                        },
                    }}
                    sx={{borderRadius: " 8px"}}
                    type={props.type}
                    placeholder={props.placeholder}
                    variant="outlined"
                    {...props}
                    label=""
                    disabled={props?.disabled}
                />
            </FormControl>
        </Box>
    )
}

export default Input
