import {components} from "./Components"
import {foundations} from "./Foundation"
import {createTheme, ThemeOptions} from "@mui/material"

const themeOptions: Omit<ThemeOptions, "components"> = {
    ...foundations,
}

export const theme: ThemeOptions = createTheme({
    ...themeOptions,
    components: {
        ...components,
    },
})
