import {Tooltip} from "@mui/material"

interface IconComponentProps {
    icon: string
    alt: string
    onClick?: () => void
}

const IconComponent = ({icon, alt, onClick}: IconComponentProps) => {
    return (
        <Tooltip title={alt}>
            <img src={icon} alt={alt} onClick={onClick} style={{cursor: "pointer"}} />
        </Tooltip>
    )
}

export default IconComponent
